export const colors = [
	{ color: "bg-rose-300" },
	{ color: "bg-red-300" },
	{ color: "bg-amber-300" },
	{ color: "bg-yellow-300" },
	{ color: "bg-lime-300" },
	{ color: "bg-green-300" },
	{ color: "bg-emerald-300" },
	{ color: "bg-teal-300" },
	{ color: "bg-cyan-300" },
	{ color: "bg-sky-300" },
	{ color: "bg-blue-300" },
	{ color: "bg-indigo-300" },
	{ color: "bg-violet-300" },
	{ color: "bg-fuchsia-300" },
	{ color: "bg-pink-300" },
	{ color: "bg-rose-600" },
	{ color: "bg-red-600" },
	{ color: "bg-amber-600" },
	{ color: "bg-yellow-600" },
	{ color: "bg-lime-600" },
	{ color: "bg-green-600" },
	{ color: "bg-emerald-600" },
	{ color: "bg-teal-600" },
	{ color: "bg-cyan-600" },
	{ color: "bg-sky-600" },
	{ color: "bg-blue-600" },
	{ color: "bg-indigo-600" },
	{ color: "bg-violet-600" },
	{ color: "bg-fuchsia-600" },
	{ color: "bg-pink-600" },
	{ color: "bg-slate-300" },
	{ color: "bg-zinc-300" },
	{ color: "bg-stone-300" },
	{ color: "bg-slate-600" },
	{ color: "bg-zinc-600" },
	{ color: "bg-stone-600" }
];

export const repeatOptions = [
	"Daily",
	"Weekly",
	"Bi Weekly",
	"Monthly",
	"Yearly",
	"Custom"
];

export const weekDays = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday"
];

export const staticTimes = [
	{
		string: "12:00 AM",
		time: "00:00:00"
	},
	{
		string: "12:30 AM",
		time: "00:30:00"
	},
	{
		string: "1:00 AM",
		time: "01:00:00"
	},
	{
		string: "1:30 AM",
		time: "01:30:00"
	},
	{
		string: "2:00 AM",
		time: "02:00:00"
	},
	{
		string: "2:30 AM",
		time: "02:30:00"
	},
	{
		string: "3:00 AM",
		time: "03:00:00"
	},
	{
		string: "3:30 AM",
		time: "03:30:00"
	},
	{
		string: "4:00 AM",
		time: "04:00:00"
	},
	{
		string: "4:30 AM",
		time: "04:30:00"
	},
	{
		string: "5:00 AM",
		time: "05:00:00"
	},
	{
		string: "5:30 AM",
		time: "05:30:00"
	},
	{
		string: "6:00 AM",
		time: "06:00:00"
	},
	{
		string: "6:30 AM",
		time: "06:30:00"
	},
	{
		string: "7:00 AM",
		time: "07:00:00"
	},
	{
		string: "7:30 AM",
		time: "07:30:00"
	},
	{
		string: "8:00 AM",
		time: "08:00:00"
	},
	{
		string: "8:30 AM",
		time: "08:30:00"
	},
	{
		string: "9:00 AM",
		time: "09:00:00"
	},
	{
		string: "9:30 AM",
		time: "09:30:00"
	},
	{
		string: "10:00 AM",
		time: "10:00:00"
	},
	{
		string: "10:30 AM",
		time: "10:30:00"
	},
	{
		string: "11:00 AM",
		time: "11:00:00"
	},
	{
		string: "11:30 AM",
		time: "11:30:00"
	},
	{
		string: "12:00 PM",
		time: "12:00:00"
	},
	{
		string: "12:30 PM",
		time: "12:30:00"
	},
	{
		string: "1:00 PM",
		time: "13:00:00"
	},
	{
		string: "1:30 PM",
		time: "13:30:00"
	},
	{
		string: "2:00 PM",
		time: "14:00:00"
	},
	{
		string: "2:30 PM",
		time: "14:30:00"
	},
	{
		string: "3:00 PM",
		time: "15:00:00"
	},
	{
		string: "3:30 PM",
		time: "15:30:00"
	},
	{
		string: "4:00 PM",
		time: "16:00:00"
	},
	{
		string: "4:30 PM",
		time: "16:30:00"
	},
	{
		string: "5:00 PM",
		time: "17:00:00"
	},
	{
		string: "5:30 PM",
		time: "17:30:00"
	},
	{
		string: "6:00 PM",
		time: "18:00:00"
	},
	{
		string: "6:30 PM",
		time: "18:30:00"
	},
	{
		string: "7:00 PM",
		time: "19:00:00"
	},
	{
		string: "7:30 PM",
		time: "19:30:00"
	},
	{
		string: "8:00 PM",
		time: "20:00:00"
	},
	{
		string: "8:30 PM",
		time: "20:30:00"
	},
	{
		string: "9:00 PM",
		time: "21:00:00"
	},
	{
		string: "9:30 PM",
		time: "21:30:00"
	},
	{
		string: "10:00 PM",
		time: "22:00:00"
	},
	{
		string: "10:30 PM",
		time: "22:30:00"
	},
	{
		string: "11:00 PM",
		time: "23:00:00"
	},
	{
		string: "11:30 PM",
		time: "23:30:00"
	}
];

export const holidays = [
	{
		color: "bg-purple-300",
		date: "1/1/2025",
		startDate: "1/1/2025",
		endDate: "1/1/2025",
		summary: "New Years Day!!",
		id: 1,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "9/4/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		time: false
	},
	{
		color: "bg-purple-300",
		date: "1/16/2025",
		startDate: "1/16/2025",
		endDate: "1/16/2025",
		summary: "Martin Luther King Jr Day!!",
		id: 2,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "9/4/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		time: false
	},
	{
		color: "bg-purple-300",
		date: "2/1/2025",
		startDate: "2/1/2025",
		endDate: "2/1/2025",
		summary: "Black History Month!!",
		id: 3,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "9/4/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		time: false
	},
	{
		color: "bg-green-300",
		date: "2/2/2025",
		startDate: "2/2/2025",
		endDate: "2/2/2025",
		summary: "Groundhog Day!!",
		id: 4,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		time: false
	},
	{
		color: "bg-red-300",
		date: "2/14/2025",
		startDate: "2/14/2025",
		endDate: "2/14/2025",
		summary: "Valentines Day!!",
		id: 5,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "9/4/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		time: false
	},
	{
		color: "bg-orange-300",
		date: "2/20/2025",
		startDate: "2/20/2025",
		endDate: "2/20/2025",
		summary: "Presedents Day!!",
		id: 6,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "9/4/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		time: false
	},
	{
		color: "bg-yellow-300",
		date: "3/12/2025",
		startDate: "3/12/2025",
		endDate: "3/12/2025",
		summary: "Daylight Savings Starts",
		id: 7,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "9/4/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		time: false
	},
	{
		color: "bg-green-300",
		date: "3/17/2025",
		startDate: "3/17/2025",
		endDate: "3/17/2025",
		summary: "St. Patricks Day!!",
		id: 8,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "9/4/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		time: false
	},
	{
		color: "bg-yellow-300",
		date: "4/7/2025",
		startDate: "4/7/2025",
		endDate: "4/7/2025",
		summary: "Good Friday!!",
		id: 9,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "9/4/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		time: false
	},
	{
		color: "bg-yellow-300",
		date: "4/9/2025",
		startDate: "4/9/2025",
		endDate: "4/9/2025",
		summary: "Easter Sunday!!",
		id: 10,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "9/4/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		time: false
	},
	{
		kind: "Event",
		color: "bg-purple-300",
		date: "5/14/2025",
		startDate: "5/14/2025",
		endDate: "5/14/2025",
		summary: "Mothers Day!!",
		description: "Happy Mothers Day!",
		id: 11,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "5/14/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		location: "",
		time: false
	},
	{
		kind: "Event",
		color: "bg-pink-300",
		date: "5/29/2025",
		startDate: "5/29/2025",
		endDate: "5/29/2025",
		summary: "Memorial Day!!",
		description: "Happy Memorial day!",
		id: 12,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "5/29/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		location: "",
		time: false
	},
	{
		kind: "Event",
		color: "bg-orange-300",
		date: "6/18/2025",
		startDate: "6/18/2025",
	 endDate: "6/18/2025",
		summary: "Fathers Day!!",
		description: "Happy Father's Day!",
		id: 13,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "5/29/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		location: "",
		time: false
	},
	{
		kind: "Event",
		color: "bg-blue-300",
		date: "7/4/2025",
		startDate: "7/4/2025",
		endDate: "7/4/2025",
		summary: "Independance Day!!",
		description: "Happy 4th of July!",
		id: 14,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "5/29/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		location: "",
		time: false
	},
	{
		color: "bg-blue-300",
		date: "9/4/2025",
		startDate: "9/4/2025",
		endDate: "9/4/2025",
		summary: "Labor Day!!",
		id: 15,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "9/4/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		location: "",
		time: false
	},
	{
		color: "bg-orange-300",
		date: "10/31/2025",
		startDate: "10/31/2025",
		endDate: "10/31/2025",
		summary: "Halloween",
		id: 16,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "10/31/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		location: "",
		time: false
	},
	{
		color: "bg-orange-300",
		date: "11/23/2025",
		startDate: "11/23/2025",
		endDate: "11/23/2025",
		summary: "Thanksgiving!!",
		id: 17,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "11/23/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		location: "",
		time: false
	},
	{
		color: "bg-green-300",
		date: "12/25/2025",
		startDate: "12/25/2025",
		endDate: "12/25/2025",
		summary: "Christmas!!",
		id: 18,
		reminders: {
			reminder: false
		},
		repeats: {
			repeat: false,
			howOften: "yearly",
			nextDate: "12/25/2024"
		},
		start: {
			startTime: null
		},
		end: {
			endTime: null
		},
		location: "",
		time: false
	}
];

export const weatherCodes = [
	{
		name: "Clear Sky",
		codes: [0],
		icon: 0
	},
	{
		name: "Partly Cloudy",
		codes: [1, 2, 3],
		icon: 1
	},
	{
		name: "Rain",
		codes: [61, 63, 65, 66, 67, 80, 81, 82],
		icon: 2
	},
	{
		name: "Snowy",
		codes: [71, 73, 75, 77, 85, 86],
		icon: 3
	},
	{
		name: "Thunder Storms",
		codes: [95, 96, 99, "*"],
		icon: 4
	},
	{
		name: "Foggy",
		codes: [45, 48],
		icon: 5
	},
	{
		name: "Light Rain",
		codes: [51, 53, 55, 56, 57],
		icon: 6
	}
];

export const staticMonths = [
	{ name: "January", num: 0 },
	{ name: "February", num: 1 },
	{ name: "March", num: 2 },
	{ name: "April", num: 3 },
	{ name: "May", num: 4 },
	{ name: "June", num: 5 },
	{ name: "July", num: 6 },
	{ name: "August", num: 7 },
	{ name: "September", num: 8 },
	{ name: "October", num: 9 },
	{ name: "November", num: 10 },
	{ name: "December", num: 11 }
];

export const staticYears = [
	"2023",
	"2024",
	"2025",
	"2026",
	"2027",
	"2028",
	"2029",
	"2030",
	"2031",
	"2032",
	"2033",
	"2034",
	"2035",
	"2036",
	"2037",
	"2038",
	"2039",
	"2040",
	"2041",
	"2042",
	"2043",
	"2044",
	"2045",
	"2046",
	"2047",
	"2048",
	"2049",
	"2050"
];
